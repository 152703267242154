import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {goToCalendar, goToDateNote} from './app.actions';
import {tap} from 'rxjs/operators';
import * as moment from 'moment';

@Injectable()
export class AppEffects {
    goToCalendar$ = createEffect(() => this.actions$.pipe(
        ofType(goToCalendar),
        tap(() => {
            this.router.navigate(['/', 'calendar']);
        })
    ), { dispatch: false });

    goToDateNote$ = createEffect(() => this.actions$.pipe(
        ofType(goToDateNote),
        tap(action => {
            const { date } = action;
            const id = moment(date).format('YYYY-MM-DD');
            this.router.navigate(['/', 'date-note', id]);
        })
    ), { dispatch: false });

    constructor(
        private actions$: Actions,
        private router: Router,
    ) { }
}
